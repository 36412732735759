import { GetData } from "../../APIcalls";
import React, { useEffect , useState} from "react";
import { Divider, Button, Modal, Row, Col } from "antd";
import { useDispatch } from "react-redux";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { setmetadataVideo } from "../../addMedia/store/addMediaActions";
import Hooks from "../utils/hooks";
import { AddHooks } from "../../addMedia/utils/hooks";
import { useAppSelector } from "../../redux/hooks";
export function MetaDataVideoModal({ video, idVideo,nameVideo }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modalToOpen = useAppSelector((state) => state.MediaReducer.modalToOpen);
  const metadata = useAppSelector((state) => state.addMediaReducer.metaData);
  const { handleCancel, visibleMetadataModal } = Hooks();
  const { formatBytes, secondsToHms } = AddHooks();
  const getMetadataInfo = async (Id) => {
    await GetData(`/video-api/video/${Id}/get-meta-data`).then((res) => {
      dispatch(setmetadataVideo(res.data?.data?.metaData?.contentJson));
    });
  };
  useEffect(() => {
    idVideo && getMetadataInfo(idVideo);
  }, [idVideo]);
  return (
    <Modal
      className="custom-modal-metadata"
      footer={[
        ,<Button key="back" onClick={handleCancel}>
       {t('close')}
        </Button>
      ]}
      onCancel={handleCancel}
      icon={<InfoCircleOutlined />}
      type="error"
      title={<span title={nameVideo}>
        {`${t("video.Metadata_video")}: ${nameVideo}`}
      </span>}
      open={modalToOpen === "showVideoDetail"}
      //open={visibleMetadataModal}
    >
      <Row>
        <Col span={20}>
          {" "}
          <b>Id</b>{" "}
        </Col>
        <InfoCircleOutlined style={{ color: "white", fontSize: "25px" }} />
        {idVideo}
      </Row>
      <Divider />
      <Row>
        <Col span={20}>
          {" "}
          <b>{t("video.metadata_bitrate")}</b>{" "}
        </Col>
        {formatBytes(metadata?.format?.bit_rate) + "/s"}
      </Row>
      <Divider />
      <Row>
        <Col span={20}>
          {" "}
          <b>{t("video.resolution")}</b>{" "}
        </Col>
        {metadata?.streams && metadata?.streams[0]?.width} *{" "}
        {metadata?.streams && metadata?.streams[0]?.height}
      </Row>
      <Divider />
      <Row>
        <Col span={20}>
          {" "}
          <b>{t("video.size")}</b>{" "}
        </Col>{" "}
        {formatBytes(metadata?.format?.size)}{" "}
      </Row>
      <Divider />
      <Row>
        <Col span={20}>
          {" "}
          <b>{t("video.duration")}</b>{" "}
        </Col>
        {secondsToHms(metadata?.format?.duration)}{" "}
      </Row>
      <Divider />
      <Row>
        <Col span={20}>
          {" "}
          <b>{t("video.frame_rate")} </b>{" "}
        </Col>
        {metadata?.streams && metadata?.streams[0]?.avg_frame_rate}
      </Row>
      <Divider />
      <Row>
        <Col span={20}>
          {" "}
          <b>{t("video.codec_video")}</b>{" "}
        </Col>{" "}
        {metadata?.streams && metadata?.streams[0]?.codec_name}{" "}
      </Row>
      <Divider />
      <Row>
        <Col span={20}>
          {" "}
          <b>{t("video.Samplerate")}</b>{" "}
        </Col>
        {metadata?.streams && metadata?.streams[1]?.codec_time_base
          ? (metadata?.streams[1]?.codec_time_base).split("/")[1]
          : "-"}
      </Row>
      <Divider />
      <Row>
        {" "}
        <Col span={20}>
          <b>{t("video.audio_codec")}</b>{" "}
        </Col>{" "}
        {metadata?.streams && metadata?.streams[1]?.codec_name
          ? metadata?.streams[1]?.codec_name
          : "-"}{" "}
      </Row>
      <Divider />
      <Row>
        <Col span={20}>
          {" "}
          <b>{t("video.Audio_bitrate")}</b>{" "}
        </Col>
        {metadata?.streams && metadata?.streams[1]?.bit_rate
          ? formatBytes(metadata?.streams[1]?.bit_rate) + "/s"
          : "-"}{" "}
      </Row>
    </Modal>
  );
}
